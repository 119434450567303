import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>Testing: Pool Request. Should auto deploy</h2>
      </header>
    </div>
  );
}

export default App;
